// import { LogoIcon } from '../Icons/Logo';
import React, { useEffect } from 'react';
import { TEL, TELE, extUrl } from '../../utils/consts';

import { ArrowLeft } from '../Icons/ArrowLeft';
// import { Burger } from '../Icons/Burger';
import { CursorFill } from '../Icons/CursorFill';
import { fetchHeader } from '../../utils/httpServices/global';
// import { SearchCustom } from '../Icons/SearchCustom';
import { menuItems } from './menuItems';
import { useQuery } from 'react-query';
import { resetSS } from '../../modules/Start';

export const handleGoBack = () => {
  const urlSS = sessionStorage.getItem('url');
  resetSS();
  window.location.href = urlSS || extUrl;
};

export default function Header() {
  const { data: header } = useQuery('header', fetchHeader, { staleTime: 60000 * 99 });
  const headerLS = localStorage.getItem('header');
  const headerPLS = headerLS && JSON.parse(headerLS);
  const hdata = header || headerPLS;

  useEffect(() => {
    localStorage.removeItem('header');

    if (header && !header?.error) {
      localStorage.setItem('header', JSON.stringify(header));
    }
  }, [header]);

  return (
    <header className="!m-0 !p-0">
      <div className="bg-[#151515] w-full px-8 py-[7px] text-sm text-[#B4B4B4] md:flex hidden justify-between">
        <div className="flex gap-1">
          <CursorFill className="lg:block hidden" />
          <div className="lg:block hidden">{hdata?.location?.[0]?.cityName || 'Москва'}</div>
          <div className="ml-3 lg:block hidden">
            {hdata?.location?.[0]?.decr ||
              'Пластиковые окна недорого в Москве — продажа и установка!'}
          </div>
        </div>
        <ul className="flex items-center gap-2">
          {(hdata?.topMenu || menuItems).map((item: any) => (
            <li
              key={item?.name || item.id}
              className="hover:text-white hover:underline cursor-pointer lg:text-sm text-xs"
              onClick={() => {
                let link = item.path || item.link;
                if (!link.includes('http')) {
                  link = extUrl + link;
                }
                window.location.href = link;
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  let link = item.path || item.link;
                  if (!link.includes('http')) {
                    link = extUrl + link;
                  }
                  window.location.href = link;
                }
              }}
              role="link"
            >
              {item.name || item.nameItem}
            </li>
          ))}
        </ul>
      </div>
      <div className="flex justify-between items-center bg-white md:h-[78px] w-full md:py-5 max-md:pt-4 max-md:pb-2 md:px-8 px-4">
        <div className="flex items-center md:gap-6 max-md:justify-between max-md:w-full overflow-hidden">
          <button
            className="p-[10px] bg-[#F4F4F4] rounded-[8px] text-[#3E3E40] flex gap-2 items-center text-[12px]"
            onClick={() => handleGoBack()}
          >
            <ArrowLeft />
            Вернуться на сайт
          </button>
          {/* <LogoIcon className="" /> */}
          <div
            className="w-[140px] h-[40px] bg-contain bg-no-repeat"
            style={{
              backgroundImage: `url(${
                hdata?.logos?.mainLogo?.imgLink ||
                'https://api.mosokna.ru/upload/iblock/5b7/io1gsqyv2ipq26t4o8kde3hvz63gokh0.svg'
              })`,
            }}
            role="button"
            tabIndex={0}
            onClick={() => handleGoBack()}
          />
        </div>
        <div className="md:flex hidden items-center">
          <div className="flex flex-col items-center mx-5">
            <div className="text-[11px] font-medium uppercase">
              {header?.phone?.[0]?.decr || 'Ежедневно с 9:00 до 21:00'}
            </div>
            <a
              className="text-[19px] font-bold hover:underline"
              href={
                header?.phone?.[0]?.defaultNumber ? `tel:${header.phone[0].defaultNumber}` : TEL
              }
            >
              {header?.phone?.[0]?.defaultNumber
                ? header.phone[0].defaultNumber.replace(
                    /^(\+\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/,
                    '$1 ($2) $3-$4-$5',
                  )
                : TELE}
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}
