import * as Yup from 'yup';

import { ErrorMessage, Field, Form, Formik } from 'formik';

import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import clsx from 'clsx';

import { useNavigate } from 'react-router-dom';

import Button from '../Button';
import { Cell } from '../Cell';
import { Divider } from '../Divider';
import { FormItem } from '../FormItem';
import { Input } from '../Input';
import { CheckboxIcon } from '../Icons/Checkbox';
import { ChevronLeftIcon } from '../Icons/ChevronLeft';
import { Price } from '../TotalPrice/Price';
import { RootState, setOrder, setShowOrder } from '../../redux/store';
import {
  getYandexMetrikaClientId,
  handleError,
  sendEventToGTM,
  sendEventToGTMNew,
} from '../../utils/helpers';
import { confirmPhone, createOrder, sendCode } from '../../utils/httpServices/global';

import { Card } from './Card';
import Cookies from 'js-cookie';

let initForm = { name: '', room: '', entrance: '', floor: '', phone: '', email: '' };

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Имя обязательно'),
  room: Yup.string().matches(/^[0-9]+$/, 'Допустимы только цифры'),
  // .required('Квартира обязателена'),
  entrance: Yup.string().matches(/^[0-9]+$/, 'Допустимы только цифры'),
  // .required('Подъезд обязателен'),
  floor: Yup.string().required('Этаж обязателен'),
  phone: Yup.string().required('Телефон обязателен'),
  email: Yup.string().email('Неправильный формат email').required('Email обязателен'),
});

export const Order = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const enabled = useSelector((state: RootState) => state.global.enabled);
  const plans = useSelector((state: RootState) => state.global.plans);
  const order = useSelector((state: RootState) => state.global.order);
  const orderState = useSelector((state: RootState) => state.global.orderState);
  const options = useSelector((state: RootState) => state.global.options);
  const prices = useSelector((state: RootState) => state.global.prices);
  const isDiscount = prices?.discount?.enabled || false;
  const isShowOrder = useSelector((state: RootState) => state.global.isShowOrder);
  const [step, setStep] = useState(1);
  const [seconds, setSeconds] = useState(59);
  const [smsCode, setSMSCode] = useState('');
  const [smsError, setSMSError] = useState(false);
  const [orderId, setOrderId] = useState<string | number>('');

  const handleSubmit = useCallback(
    async (details: typeof initForm) => {
      const newOrderData = order ? { ...order, details } : { details };
      dispatch(setOrder(newOrderData));
      sendEventToGTMNew('step4', 'add_clickBtn');
      try {
        await sendCode({ phone: details.phone });
        setStep(2);
      } catch (error: any) {
        console.error('sendCode ERROR: ', error);
        if (error?.data) handleError({ message: error?.data });
      }
    },
    [dispatch, order],
  );

  const handleCheck = async () => {
    try {
      const res = await confirmPhone({ phone: order?.details?.phone, code: smsCode });
      setSMSError(res?.error);

      if (smsCode.length === 4 && !!!res?.error) {
        handleCreateOrder();
      }
      return !!!res?.error;
    } catch (e) {
      console.log('error:', e);
      setSMSError(true);
      return false;
    }
  };

  const goBack = () => {
    sessionStorage.setItem('isReturn', '1');
    dispatch(setShowOrder(false));
  };

  const handleRepeatSMS = useCallback(async () => {
    if (order?.details?.phone) {
      await sendCode({ phone: order.details.phone });
    }
  }, [order]);

  const handleFinish = () => {
    const urlSS = sessionStorage.getItem('url');
    if (urlSS) {
      setStep(1);
      window.location.href = urlSS;
    } else {
      setTimeout(() => {
        setStep(1);
        navigate('/');
      }, 333);
    }
  };

  useEffect(() => {
    const h2 = document.querySelector('h2');
    if (h2) {
      h2.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }

    if (order?.details) {
      initForm = { ...initForm, ...order.details };
    }

    if (step === 2) {
      const interval = setInterval(() => {
        setSeconds((prev) => (prev > 0 ? prev - 1 : prev));
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [step]);

  const handleCreateOrder = async () => {
    const clientID = await getYandexMetrikaClientId(1145280);

    const orderData: any = {
      name: order?.details.name || '',
      email: order?.details.email || '',
      room: order?.details?.room ? +order.details.room : '',
      entrance: order?.details?.entrance ? +order.details.entrance : '',
      client_id: clientID || 'test', // utm обязательно
      source: 'Google', // utm nullable
      campaign: null, // utm nullable
      medium: null, // utm nullable
      content: null, // utm nullable
      request: null, // utm nullable
      session_id: Cookies.get('_ct_session_id') || '',
      call_value: Cookies.get('_ct_session_id') || '',
    };
    const utmSS = sessionStorage.getItem('utm');
    if (utmSS) {
      const utm = JSON.parse(utmSS);
      if (utm._ct_session_id) {
        // _ga
        // _ym_uid
        // _ct_session_id;
        // orderData.client_id = utm._ym_uid || utm._ga || utm._ct_session_id;
        orderData.session_id = utm._ct_session_id;
      }
      if (utm.utm_source) orderData.source = utm.utm_source;
      if (utm.utm_campaign) orderData.campaign = utm.utm_campaign;
      if (utm.utm_medium) orderData.medium = utm.utm_medium;
      if (utm.utm_content) orderData.content = utm.utm_content;
      if (utm.utm_term) orderData.request = utm.utm_term;
    } else {
      const sourceData = window.sbjs?.get?.current;
      orderData.source = sourceData?.src;
      orderData.campaign = sourceData?.cmp;
      orderData.medium = sourceData?.mdm;
      orderData.content = sourceData?.cnt;
      orderData.request = sourceData?.trm;
    }

    createOrder(order?.planId || 0, orderData)
      .then(async (res) => {
        if (res?.error) throw new Error(res.error);

        if (res?.order_id) {
          sendEventToGTM('Calculator');

          setOrderId(res.order_id);
        }

        setTimeout(() => {
          setStep((p) => p + 1);
        }, 666);
      })
      .catch((error: any) => {
        console.error(error);
        handleError({ error });
      });
  };

  useEffect(() => {
    if (!smsCode) {
      setSMSError(false);
    }
  }, [smsCode]);

  useEffect(() => {
    setOrderId('');
  }, []);

  const montazhW = order?.windowsArr1
    ?.map((el: any, i: number) => (enabled?.windows?.[i] ? orderState?.items?.windows?.[i] : null))
    .filter(Boolean)
    .map((el: any) => el.options.install?.values?.[0]?.price);
  const montazhB = order?.balconiesArr1
    ?.map((el: any, i: number) =>
      enabled?.balconies?.[i] ? orderState?.items?.balconies?.[i] : null,
    )
    .filter(Boolean)
    .map((el: any) => el.options.install?.values?.[0]?.price);

  const montazh = [...montazhB, ...montazhW].reduce((acc, cur) => acc + cur, 0);

  let address = sessionStorage.getItem('address') || '';
  if (address) {
    const obj = JSON.parse(address);
    address = obj?.address;
  }

  if (!isShowOrder) return null;

  return step === 1 ? (
    <div className="lg:p-8">
      <div className="flex lg:flex-row flex-col lg:gap-5 gap-3 h-fit">
        <Cell className="lg:w-2/3 w-full">
          <Button
            variant={'base'}
            className="text-[#FF4545] text-base flex items-center gap-1 lg:mb-6 mb-5 "
            onClick={goBack}
          >
            <ChevronLeftIcon color="#FF4545" />
            Вернуться к заказу
          </Button>
          <h1 className="font-Istok lg:hidden text-[25px] mb-2">Оформление заказа</h1>
          <h2 className="font-Istok lg:text-[30px] text-[21px] mb-2">Состав заказа</h2>
          <p className="lg:text-[20px] text-[17px] font-normal">
            Проверьте правильность вашего заказа
          </p>

          <div className="flex flex-col gap-4 lg:mt-8 mt-4">
            {order?.windowsArr1?.map((el: any, i: number) =>
              enabled?.windows?.[i] ? (
                <Card
                  key={JSON.stringify(el)}
                  data={el}
                  options={options?.windows?.[i]}
                  item={orderState?.items?.windows?.[i]}
                />
              ) : null,
            )}

            {order?.balconiesArr1?.map((el: any, i: number) =>
              enabled?.balconies?.[i] ? (
                <Card
                  key={JSON.stringify(el)}
                  data={el}
                  options={options?.balconies?.[i]}
                  item={orderState?.items?.balconies?.[i]}
                />
              ) : null,
            )}
          </div>

          <div className="flex flex-col gap-3 w-full mt-8">
            <Price
              name="Стоимость конструкций"
              value={`${prices.total - (prices?.delivery || 0) - (montazh || 0)} ₽`}
            />
            <Price name="Доставка с разгрузкой" value={`${prices?.delivery} ₽`} />
            {!!montazh && <Price name="Установка конструкций" value={`${montazh} ₽`} />}
            {/* <Price name="Установка конструкций" value="??? ₽" /> */}
            {isDiscount && <Price name="Скидка" value={prices.discount.total + ' ₽'} />}
            <Divider />
            <Price
              name="Итоговая сумма"
              value={(isDiscount ? prices.total - prices.discount.total : prices?.total) + ' ₽'}
              className="lg:text-[32px] text-[23px]"
            />
          </div>
          <Button variant={'outline'} className="mt-8 bg-white lg:w-auto w-full" onClick={goBack}>
            Вернуться и изменить расчёт
          </Button>
        </Cell>
        <Cell className="lg:w-1/3 w-full">
          <h2 className="font-Istok lg:text-[30px] text-[23px]">Ваши данные</h2>
          <p className="lg:text-[20px] text-[17px] font-normal mt-1 lg:mb-8 mb-5">
            {plans?.address || address}
          </p>
          <Formik
            initialValues={{ ...initForm, floor: order?.floor || '' }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {({ errors, touched, isValid, values }) => (
              <Form className="flex flex-col lg:gap-6 gap-4 mt-6 w-full">
                Личные данные
                <FormItem name="name" placeholder="Имя" error={errors.name && touched.name} />
                Адрес
                <div className="flex gap-4">
                  <FormItem
                    name="room"
                    placeholder="Квартира"
                    error={errors.room && touched.room}
                  />
                  <FormItem
                    name="entrance"
                    placeholder="Подъезд"
                    error={errors.entrance && touched.entrance}
                  />
                  <FormItem
                    name="floor"
                    placeholder="Этаж"
                    error={errors.floor && touched.floor}
                    disabled
                  />
                </div>
                Контактные данные
                <Field name="phone">
                  {({ field, form, meta }: any) => (
                    <div>
                      <Input
                        type="tel"
                        placeholder="Телефон +7 (XXX) XXX-XX-XX"
                        {...field}
                        {...form}
                        className={clsx(
                          'w-full font-normal',
                          meta.touched && meta.error && 'border-[#f00]',
                        )}
                      />
                      {meta.touched && meta.error && (
                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="text-[#f00] pt-1 pl-4 font-Istok text-xs"
                        />
                      )}
                    </div>
                  )}
                </Field>
                <FormItem name="email" placeholder="E-mail" error={errors.email && touched.email} />
                <Button
                  variant="red"
                  className="ml-auto lg:w-auto w-full"
                  type="submit"
                  disabled={
                    !isValid || !values.email || !values.phone || !values.floor || !values.name
                  }
                >
                  Далее
                </Button>
              </Form>
            )}
          </Formik>
        </Cell>
      </div>
    </div>
  ) : step === 2 ? (
    <div className="px-8">
      <Button
        variant={'base'}
        className="text-[#FF4545] text-base flex items-center gap-1 lg:mb-6 max-lg:my-3"
        onClick={() => setStep(1)}
      >
        <ChevronLeftIcon color="#FF4545" />
        Вернуться
      </Button>
      <Cell className="max-w-[505px]">
        <h2 className="lg:mb-2 lg:text-[26px] text-[19px] font-Istok">
          Подтвердите номер телефона
        </h2>
        <p className="font-normal lg:text-[20px] lg:mb-6 max-lg:my-3">
          Для отправки заявки введите код из СМС,
          <br />
          который мы отправили на номер {order?.details?.phone}
        </p>
        <Input
          animatedPlaceholder
          placeholder="Код из СМС"
          className="w-full h-[56px]"
          onClear={() => setSMSCode('')}
          onChange={(e) => setSMSCode(e.target.value)}
          ok={smsCode.length === 4 && !smsError}
          // ok={true}
          value={smsCode}
        />
        {smsError && (
          <p className="text-xs font-Istok font-normal mt-1 text-[#FF4545] mt-1 ml-4">
            Неверный код
          </p>
        )}
        <div className="flex max-lg:flex-col gap-2 items-center mt-4">
          <Button
            onClick={handleRepeatSMS}
            className="whitespace-nowrap"
            variant="white"
            disabled={!!seconds}
            style={!!seconds ? { color: '#ACACAC' } : {}}
          >
            Отправить код ещё раз{seconds ? `: 00:${String(seconds).padStart(2, '0')}` : ''}
          </Button>
          <Button
            variant="base"
            className="whitespace-nowrap font-Istok"
            onClick={() => setStep(1)}
          >
            Изменить номер
          </Button>
        </div>
        <p className="mt-6 font-normal text-sm">
          После подтверждения заказа с Вами свяжется персональный менеджер для согласования деталей
          заказа, способа оплаты, даты доставки и монтажа изделий.
          <br />
          <br />
          <u
            role="button"
            tabIndex={0}
            onClick={() => {
              window.open('https://www.mosokna.ru/privacy-policy', '_blank');
            }}
          >
            Оставляя заявку, Вы принимаете условия хранения персональных данных.
          </u>
        </p>
        <Button variant="red" className="mt-4 w-full" onClick={() => handleCheck()}>
          Отправить заявку
        </Button>
      </Cell>
    </div>
  ) : (
    <div>
      <Cell className="mx-auto w-[423px] flex flex-col items-center">
        <CheckboxIcon className="mb-6 mx-auto" />
        <h2 className="font-Istok text-[26px] text-center">
          Ваша заявка №{orderId || '-'}
          <br />
          успешно оформлена
        </h2>
        <p className="font-normal text-[20px] text-center mt-2 mb-6">
          В ближайшее время по указанному номеру с вами свяжется наш менеджер
        </p>
        <Button variant="red" className="mx-auto w-[59%]" onClick={() => handleFinish()}>
          На главную
        </Button>
      </Cell>
    </div>
  );
};
