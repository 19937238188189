import { DEBOUNCE_TIME_MS, MIN_LENGTH } from '../../utils/consts';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { fetchAddresses, fetchPlans } from '../../utils/httpServices/global';
import { resetState, setPlans } from '../../redux/store';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { AddressData } from './types';
import { BuildingIcon } from '../../components/Icons/Building';
import Dropdown from '../../components/Dropdown';
import clsx from 'clsx';
import { debounce } from 'lodash';
import { useDispatch } from 'react-redux';
import { sendEventToGTMNew } from '../../utils/helpers';

export const resetSS = () => {
  sessionStorage.removeItem('fias_id');
  sessionStorage.removeItem('url');
  sessionStorage.removeItem('utm');
  sessionStorage.removeItem('isReturn');
  sessionStorage.removeItem('address');
  sessionStorage.removeItem('name');
  sessionStorage.removeItem('roomsNum');
  sessionStorage.removeItem('plans');
  sessionStorage.removeItem('plan');
  sessionStorage.removeItem('innerHeight');
};

export default function Start() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [searchParams] = useSearchParams();

  const allParams = useMemo(() => Object.fromEntries(searchParams), [searchParams]);

  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState('');
  const [options, setOptions] = useState<AddressData[] | null>(null);
  const [selected, setSelected] = useState<{ value: string; label: string } | null>(null);

  const onInput = useMemo(
    () =>
      debounce(async (address: any) => {
        setIsLoading(true);

        if (address.length >= MIN_LENGTH) {
          const res = await fetchAddresses(address);
          setOptions(res?.suggestions || null);
        } else {
          setOptions(null); // Очистка данных при коротком вводе текста
        }
        setIsLoading(false);
      }, DEBOUNCE_TIME_MS),
    [],
  );

  const handleFinish = useCallback(
    async (edata: null | { fias_id: string; address: string } = null) => {
      sessionStorage.removeItem('isDefault');
      sessionStorage.removeItem('address');

      if (edata || (selected?.label === value && selected)) {
        const rdata = {
          fias_id: selected?.value || options?.[0].data.fias_id,
          address: selected?.label || options?.[0].unrestricted_value,
        };
        setIsLoading(true);

        sendEventToGTMNew('step1', 'add_clickBtn');
        const res = await fetchPlans(edata || rdata);
        sessionStorage.setItem(
          'address',
          JSON.stringify(edata ? { ...edata, address: res?.address || res?.[0]?.address } : rdata),
        );

        setIsLoading(false);

        let name = res?.name || '';
        if (name && name.toLowerCase().includes('axios')) {
          name = '';
        }
        sessionStorage.setItem('name', name); // 222 case with .name

        // 200 case
        if (res?.plans) {
          sessionStorage.setItem('plans', JSON.stringify(res));
          dispatch(setPlans(res));
          navigate('/calc/plan');
        }

        // 222 case && 404 case
        if (res?.response?.data || res?.name) {
          navigate('/calc/open-plan');
        }
      }
    },
    [dispatch, navigate, options, selected, value],
  );

  useEffect(() => {
    resetSS();
    dispatch(resetState());
    // sessionStorage.setItem('utm', JSON.stringify({ _ct_session_id: 'TEST_SESSION_ID' }));
    console.log('reset SS + reset state');
  }, []);

  useEffect(() => {
    if (allParams && Object.values(allParams).length > 0) console.log('allParams', allParams);
  }, [allParams]);

  useEffect(() => {
    if (inputRef?.current && !allParams?.fias_id) {
      setTimeout(() => {
        (inputRef.current as any).focus();
      }, 99);
    }
  }, [options]);

  useEffect(() => {
    const checkTokenAndFinish = () => {
      const token = sessionStorage.getItem('token');
      if (token) {
        const { fias_id, url, ...utm } = allParams;
        sessionStorage.setItem('fias_id', fias_id);
        sessionStorage.setItem('url', url);
        sessionStorage.setItem('utm', JSON.stringify(utm));
        console.log('save to SS utm:', utm);
        handleFinish({ fias_id: allParams.fias_id, address: '' });
      } else {
        setTimeout(checkTokenAndFinish, 100); // Повторная проверка через 100 мс
      }
    };

    if (allParams?.fias_id) {
      checkTokenAndFinish();
    }
  }, [allParams, handleFinish]);

  useEffect(() => {
    setOptions(null);
  }, [selected]);

  if (allParams?.fias_id) return <div className="fixed top-0 left-0 right-0 bottom-0" />;

  return (
    <div className="w-full bg-[#000a] flex flex-col justify-center flex-grow">
      <div
        className={clsx(
          'w-full lg:h-[500px] xl:px-[80px] px-[5vw] flex flex-col justify-center bg-white rounded-2xl font-bold text-gray-700 relative',
          'max-lg:pt-[60px] max-lg:pb-4 rounded-2xl',
        )}
        style={{ fontFamily: 'Calibri' }}
      >
        <div
          className="absolute bg-cover bg-center bg-no-repeat left-0 right-0 top-0 bottom-0 max-lg:hidden flex"
          style={{ backgroundImage: `url('/images/start_bg.jpg')` }}
        />
        <div
          className="absolute bg-cover bg-center bg-no-repeat left-0 right-0 top-0 bottom-0 max-lg:flex hidden"
          style={{ backgroundImage: `url('/images/start_bgv.jpg')` }}
        />
        <div className="z-50">
          <p className="mb-4 select-none xl:text-[32px] text-[25px]">
            Введите адрес дома,
            <br />
            где собираетесь менять окна, и узнайте их цену
          </p>
          <div className="xl:w-[800px] w-full md:min-w-[40vw] min-w-full">
            <Dropdown
              className="h-[64px] w-full"
              options={
                options?.map((el) => ({
                  label: el.unrestricted_value,
                  value: el.data.fias_id,
                  data: el,
                })) || null
              }
              onFinish={() => handleFinish(null)}
              setValue={setValue}
              selected={selected}
              setSelected={setSelected}
              onChange={(val: string) => {
                setValue(val);
                onInput(val);
              }}
              value={value}
              isLoading={isLoading}
              ref={inputRef}
            />
          </div>
          <div className="text-sm font-normal flex flex-col gap-2 mt-4 max-lg:mt-20 select-none">
            <div
              className="flex gap-1 items-center w-fit"
              role="button"
              tabIndex={0}
              onClick={() => {
                window?.open(
                  'https://www.youtube.com/watch?v=szPDeYMN1UA&ab_channel=%D0%A1%D0%BA%D0%B0%D1%87%D0%B0%D0%B9%D0%9E%D0%BA%D0%BD%D0%BE',
                  '_blank',
                );
              }}
            >
              <BuildingIcon /> Как это работает?
            </div>
            {/* <div
              className="gap-1 font-semibold items-center flex w-fit"
              role="button"
              tabIndex={0}
            >
              <BuildingIcon /> Выбрать дом на карте
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
