import { MinusIcon, PlusIcon } from '../Icons/PlusMinus';
import React from 'react';
import clsx from 'clsx';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  variant?: 'outline' | 'base' | 'dark' | 'white' | 'red' | 'plus' | 'minus' | 'whiteRed' | 'grey';
  selected?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { children = null, className, selected = false, variant = 'base', ...rest } = props;

  return (
    <button
      ref={ref}
      className={clsx(
        'rounded-lg flex justify-center items-center transition-all select-none',
        variant === 'outline' &&
          'border border-[#2F2F2F] lg:px-3 lg:py-2 px-2 py-[6px] lg:text-base text-sm',
        variant === 'base' && 'px-4 py-2',
        variant === 'dark' && 'bg-[#3E3E40] text-white px-6 py-4 ',
        variant === 'white' &&
          'bg-white text-[#3E3E40] lg:px-8 lg:py-[10px] px-5 py-2 font-Istok lg:text-base text-sm',
        variant === 'white' && selected && '!bg-[#FF4545] !text-white',
        variant === 'red' &&
          '!bg-[#FF4545] !text-white w-fit lg:px-8 lg:py-[10px] px-4 py-2 disabled:!bg-[#FFC8C8] font-Istok lg:text-base text-sm',
        variant === 'grey' &&
          '!bg-[#F4F4F4] !text-[#3E3E40] w-fit px-8 py-[10px] font-Istok lg:text-base text-sm',
        variant === 'whiteRed' &&
          '!bg-white !text-[#FF4545] w-fit px-6 py-4 border border-[#FF4545] disabled:opacity-75',
        (variant === 'plus' || variant === 'minus') && 'bg-[#F4F4F4] w-[22px] h-[22px]',
        className,
      )}
      {...rest}
    >
      {children}
      {variant === 'plus' && <PlusIcon />}
      {variant === 'minus' && <MinusIcon />}
    </button>
  );
});

Button.displayName = 'Button';

export default Button;
