import { ReactNode } from 'react';

import { toast } from 'react-toastify';

import sourcebuster from 'sourcebuster';

export const getYandexMetrikaClientId = (counterId: string | number): Promise<string> => {
  return new Promise((resolve, reject) => {
    if (window.ym) {
      window.ym(counterId, 'getClientID', (clientId: any) => {
        resolve(clientId);
      });
    } else {
      reject('Yandex Metrika not loaded');
    }
  });
};

export const initializeSourcebuster = () => {
  sourcebuster.init({
    cookieDomain: window.location.hostname,
    cookieExpires: 30,
    campaignParams: {
      utm_source: 'utm_source',
      utm_medium: 'utm_medium',
      utm_campaign: 'utm_campaign',
      utm_content: 'utm_content',
      utm_term: 'utm_term',
    },
  });
};

export const sendEventToGTM = (eCategory: string = 'Calculator') => {
  const eventData = {
    eCategory,
    eAction: 'sentForm',
    eLabel: '',
    event: 'GTMForms',
  };
  console.log('Sending event to GTM: ', eventData);
  (window.dataLayer = window.dataLayer || []).push(eventData);
};

export const sendEventToGTMNew = (eCategory: string, eAction: string) => {
  const eventData = {
    eCategory,
    eAction,
    event: 'GTMButtons',
  };
  console.log('Sending event to GTM: ', eventData);
  (window.dataLayer = window.dataLayer || []).push(eventData);
};

// export const sendToCallTouch = async (data: CallTouchData) => {
//   try {
//     const response = await axios.post(
//       'https://api.calltouch.ru/calls-service/RestAPI/requests/orders/register/',
//       { clientApiId, ...data },
//       {
//         headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
//         transformRequest: [
//           (data) =>
//             Object.keys(data)
//               .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
//               .join('&'),
//         ],
//       },
//     );
//     console.log('CallTouch response:', response.data);
//   } catch (error) {
//     console.error('Error sending data to CallTouch:', error);
//   }
// };

export const checkIfBalcony = (name: string) => {
  const nameL = name.toLowerCase();
  const isBalcony = nameL.includes('балкон') || nameL.includes('лоджия');
  return isBalcony;
};

export const copyToClipboard = (text: string, msg: ReactNode) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      toast(msg, { type: 'success', autoClose: 2000 });
    })
    .catch((error) => {
      console.error('Ошибка при копировании текста', error);
    });
};

export function handleError({ error, message }: { error?: any; message?: string }) {
  // this.stopRequest();
  const msg =
    message ||
    error?.response?.data?.message ||
    error?.message ||
    typeof error?.response?.data === 'string'
      ? error?.response?.data
      : 'Ошибка сервера';
  toast(msg, { type: 'error' });
  console.error(error || msg);
}

export const FormatNumber = (number: any) => {
  if (Number.isNaN(number)) return 0;
  return new Intl.NumberFormat('ru-RU', { maximumFractionDigits: 2 })
    .format(number)
    .replace(',', '.');
};

export const formatNumber = (number: any) =>
  new Intl.NumberFormat('ru-RU', { maximumFractionDigits: 2 }).format(number).replace(',', '.');

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export const formatPhone = (phone: string) => phone.replace(/\D/g, '');

export const toFormData = (obj: any) => {
  const formData = new FormData();
  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value === 'string') formData.append(key, value);
  });
};

// Функция для форматирования даты и времени ISO => ru-RU
export const FormatDate = (date: string | number | Date) =>
  new Date(date).toLocaleString('ru-RU', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });

export const FormatFilledTableCell = (item: string, color = '#7A9AF8') => {
  const min = 0;
  const max = 100;
  const value: string =
    typeof item === 'string'
      ? Number.parseFloat(item.replace(/([^\d,.]+)/gi, '').replace(',', '.')).toFixed(2)
      : Number.parseFloat(item).toFixed(2);
  const filled = ((+value - min) / (max - min)) * 100;
  return `linear-gradient(to right, ${color} 0% ${filled}%, transparent ${filled}% ${
    100 - filled
  }%)`;
};
